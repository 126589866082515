'use strict';

/**
 * @function
 * @description Breakpoints
 */
const BREAKPOINTS = {
  xs: 375,
  s: 767,
  m: 768,
  l: 1025,
  xl: 1200,
};

/**
 * @function
 * @description  Constants used to help Front End Javascript
*/
const KEYBOARD = {
    TAB_KEY: 9,
    ENTER_KEY: 13,
    ESC_KEY: 27,
    SPACE_KEY: 32,
    ARROW_LEFT_KEY: 37,
    ARROW_RIGHT_KEY: 39
}

const POSTAL_CODE_FORMATS = {
  'NL': /^([1-9][0-9]{3})\s??(?!SA|SD|SS|sa|sd|ss)([a-zA-Z]{2})$/,
  'BE': /^[1-9]{1}[0-9]{3}$/,
  'SE': /^[1-9]\d{2}( )?\d{2}$/
}

function getScreenSizeType() {
  const { innerWidth } = window;
  const { s, m, l } = BREAKPOINTS;

  if (innerWidth <= s) {
    return ['mobile'];
  } else if (innerWidth > s && innerWidth <= m) {
    return ['mobile-ls', 'tablet-pt'];
  } else if (innerWidth > m && innerWidth <= l) {
    return ['tablet-ls'];
  } else if (innerWidth > l) {
    return ['desktop'];
  }
}

// Better in terms of modularity than getScreenSizeType()
function getScreenSize(mediaQuery) {
  return window.matchMedia(mediaQuery).matches;
}

function isPostalCodeEntry(entry, countryCode) {
  // default value is checking if the entry starts with digits, which is valid for both NL and BE so we don't override the value
  // but I will keep the switch-case in comment to save the meaning of the code for future when new markets with different postal code formats emerge
  var postalCodeRegex = /^\d+/;
  switch (countryCode) {
      case 'NL':
      case 'BE':
      case 'SE':
          break;
      default:
          break;
  }

  if (postalCodeRegex.test(entry)) {
      return true;
  }
  return false;
}

function isValidPostalCode(entry, countryCode) {
  var postalCodeRegex = POSTAL_CODE_FORMATS[countryCode];
  if (postalCodeRegex.test(entry)) {
      return true;
  }
  return false;
}

function findAPISearchInput(components, type) {
    var searchInput = components.find(function (component) {
        return component.types.find(function (city) {
            return city === type;
        });
    });

    return searchInput;
}

//in some cases, user can find postal address like 6666, which is city postal address, but our code expects either regular postal address or city name
function postalCodeOrCity(components, countryCode) {
    var addressSearch = '';
    var postalCode = findAPISearchInput(components, 'postal_code');
    var cityName = findAPISearchInput(components, 'locality');
    if (!cityName) {
        cityName = findAPISearchInput(components, 'administrative_area_level_3');
    }
    var provinceName = findAPISearchInput(components, 'sublocality');
    if (postalCode && isValidPostalCode(postalCode.short_name, countryCode)) {
        addressSearch = postalCode.short_name;
    } else if (cityName) {
        addressSearch = cityName.short_name && cityName.short_name.length > 2 ? cityName.short_name : cityName.long_name;
    } else if (provinceName) {
        addressSearch = provinceName.short_name;
    } else {
        var inputValue = $('.store-locator').find('[name="postalCode"]');
        addressSearch = inputValue.val();
    }
    return addressSearch;
}

module.exports = {
  KEYBOARD: KEYBOARD,
  getScreenSizeType: getScreenSizeType,
  getScreenSize: getScreenSize,
  isPostalCodeEntry: isPostalCodeEntry,
  isValidPostalCode: isValidPostalCode,
  postalCodeOrCity: postalCodeOrCity
}
